export type SearchGroup = {
  id: string
  name: string
  children: SearchGroup[]
}

export const SEARCH_GROUP_DATASET: SearchGroup = {
  id: 'DATASET',
  name: 'Dataset',
  children: [],
}

export const SEARCH_GROUP_PUBLIKASI: SearchGroup = {
  id: 'PUBLIKASI',
  name: 'Publikasi',
  children: [],
}

export const SEARCH_GROUP_DATA_INDUK_SATPEN: SearchGroup = {
  id: 'DATA_INDUK_SATPEN',
  name: 'Satuan Pendidikan',
  children: [],
}

export const SEARCH_GROUP_DATA_INDUK_YAYASAN: SearchGroup = {
  id: 'DATA_INDUK_YAYASAN',
  name: 'Badan Penyelenggara (Yayasan)',
  children: [],
}

export const SEARCH_GROUP_DATA_INDUK_PTK: SearchGroup = {
  id: 'DATA_INDUK_PTK',
  name: 'Pendidik dan Tenaga Kependidikan',
  children: [],
}

export const getSearchGroupsDataInduk = ({
  withPtk,
}: {
  withPtk: boolean
}): SearchGroup => {
  return {
    id: 'DATA_INDUK',
    name: 'Data Induk',
    children: [
      SEARCH_GROUP_DATA_INDUK_SATPEN,
      SEARCH_GROUP_DATA_INDUK_YAYASAN,
      withPtk ? SEARCH_GROUP_DATA_INDUK_PTK : null,
    ].filter(Boolean) as SearchGroup[],
  }
}

export const getSearchGroups = ({
  withPtk,
}: {
  withPtk: boolean
}): SearchGroup[] => {
  return [
    SEARCH_GROUP_DATASET,
    getSearchGroupsDataInduk({ withPtk }),
    SEARCH_GROUP_PUBLIKASI,
  ]
}

export const SEARCH_GROUPS_BY_ID = {
  DATASET: SEARCH_GROUP_DATASET.name,
  PUBLIKASI: SEARCH_GROUP_PUBLIKASI.name,
  DATA_INDUK_SATPEN: SEARCH_GROUP_DATA_INDUK_SATPEN.name,
  DATA_INDUK_YAYASAN: SEARCH_GROUP_DATA_INDUK_YAYASAN.name,
  DATA_INDUK_PTK: SEARCH_GROUP_DATA_INDUK_PTK.name,
}

export const SEARCH_TARGET_ADDRESS_BY_ID = {
  DATASET: '/dataset?s=[KEYWORD]',
  PUBLIKASI: '/publikasi?s=[KEYWORD]',
  DATA_INDUK_SATPEN:
    '/data-induk/search?s=[KEYWORD]&lid=000000&ll=Nasional&fr=%2F',
  DATA_INDUK_YAYASAN:
    '/data-induk/yayasan/search?s=[KEYWORD]&lid=000000&ll=Nasional&fr=%2F',
  DATA_INDUK_PTK:
    '/data-induk/ptk/search?s=[KEYWORD]&lid=000000&ll=Nasional&fr=%2F',
}

export const getSearchTargetAddressById = (group, keyword) => {
  return SEARCH_TARGET_ADDRESS_BY_ID[group].replace('[KEYWORD]', keyword)
}
