'use client'

import style from './hero-section.module.css'

import clsx from 'clsx'

import { useMedia } from '@wartek-id/react-utils'
import { Text } from '@wartek-id/text'

import { Container } from '@/components/Container'

import { ASSET_PREFIX } from '@/configs/constants'

export const HeroSection = () => {
  const isSmallScreen = useMedia('(max-width: 640px)', false)
  const isVerySmallScreen = useMedia('(max-width: 350px)', false)

  return (
    <div
      className={clsx(
        'min-h-[400px] w-full bg-blue-5 bg-contain bg-center bg-no-repeat py-8',
        style.heroImage
      )}
    >
      <Container>
        <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2">
          <div className="flex flex-1 flex-col gap-4 py-4 text-blue-90">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M69.032 15.4C72.7144 15.4 75.6996 12.4003 75.6996 8.7C75.6996 4.99969 72.7144 2 69.032 2C65.3496 2 62.3644 4.99969 62.3644 8.7C62.3644 12.4003 65.3496 15.4 69.032 15.4Z"
                fill="#FFC353"
              />
              <path
                d="M44.6801 71.2C34.0816 71.2 25.4834 62.56 25.4834 51.91C25.4834 43.73 30.5588 36.74 37.704 33.94L33.5244 17.16C18.9154 21.89 8.34674 35.66 8.34674 51.91C8.34674 72.07 24.6176 88.42 44.6801 88.42C46.8894 88.42 49.0489 88.21 51.1387 87.83L46.959 71.06C46.2127 71.15 45.4464 71.2 44.6801 71.2Z"
                fill="#1E1F1F"
              />
              <path
                d="M44.6805 32.63C52.0248 32.63 58.4038 36.78 61.6282 42.86L78.5161 38.61C73.2218 25.03 60.0757 15.4 44.6805 15.4C42.4713 15.4 40.3217 15.61 38.2219 15.99L42.4016 32.76C43.148 32.67 43.9043 32.62 44.6805 32.62V32.63Z"
                fill="#FFC353"
              />
              <path
                d="M63.3497 47.44C63.6881 48.88 63.8772 50.37 63.8772 51.91C63.8772 60.09 58.8118 67.07 51.6566 69.88L55.8362 86.66C70.4452 81.93 81.0138 68.16 81.0138 51.91C81.0138 48.93 80.6456 46.03 79.9789 43.25L63.3497 47.43V47.44Z"
                fill="#0B5FEF"
              />
            </svg>
            <Text
              variant={
                isVerySmallScreen
                  ? 'display-sm'
                  : isSmallScreen
                    ? 'display-lg'
                    : 'headline-xs'
              }
              color="inherit"
            >
              Selamat Datang <br />
              Di Portal Data Kemendikbudristek
            </Text>
            <Text variant="action" color="default">
              Beragam data pendidikan, kebudayaan, dan kebahasaan yang
              akuntabel, terintegrasi, dan akurat dapat diakses dan
              dibagipakaikan melalui Portal Data Kemendikbudristek.
            </Text>
          </div>
          <div className="hidden items-center justify-center py-4 md:flex">
            <picture>
              <source
                type="image/webp"
                srcSet={`${ASSET_PREFIX}/images/home-illustration.webp`}
              />
              <source
                type="image/png"
                srcSet={`${ASSET_PREFIX}/images/home-illustration.png`}
              />
              <img
                src={`${ASSET_PREFIX}/images/home-illustration.png`}
                alt="Portal Data Kemendikbudristek Logo"
                className="h-auto w-[80%] md:mx-auto md:w-full"
              />
            </picture>
          </div>
        </div>
      </Container>
    </div>
  )
}
