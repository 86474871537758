'use client'

import styles from './schedule.module.css'

import clsx from 'clsx'

import { useMedia } from '@wartek-id/react-utils'
import { Text } from '@wartek-id/text'

import { DotIndicator } from './DotIndicator'

const SCHEDULE = [
  {
    month: 'Oktober',
    text: 'Penyusunan Dummy Table',
    isStart: true,
    isFinish: false,
  },
  {
    month: 'November',
    text: 'Persiapan Cut Off Data',
    isStart: false,
    isFinish: false,
  },
  {
    month: 'Desember',
    text: 'Pengolahan Data',
    isStart: false,
    isFinish: false,
  },
  {
    month: 'Januari',
    text: 'Review Finalisasi',
    isStart: false,
    isFinish: false,
  },
  {
    month: 'Februari',
    text: 'Rilis',
    isStart: false,
    isFinish: true,
  },
]

export const ScheduleStatistik = () => {
  const isMd = useMedia('(min-width: 768px)', false)
  return (
    <div
      className={clsx(
        'relative space-y-2 rounded-lg bg-blue-90 px-4 py-8',
        styles.patternAdd
      )}
    >
      <Text variant="display-sm" color="inverse">
        Penyusunan Buku Statistik Persekolahan
      </Text>
      <ol className="relative flex w-full flex-col overflow-hidden md:flex-row">
        {SCHEDULE.map((item, index) => (
          <li
            key={item.month}
            className={clsx(
              'relative flex flex-1 flex-col items-start justify-center py-4 md:items-center',
              isMd ? '' : styles.withLeftBorder,
              isMd ? '' : '-mt-[70px]',
              index === 0 && 'is--head',
              index === SCHEDULE.length - 1 && 'is--tail'
            )}
          >
            <DotIndicator
              text={item.text}
              isActive={index === SCHEDULE.length - 1}
            />

            <div
              className={clsx(
                'relative bottom-4 hidden w-full md:block',
                index === 0 && 'ml-[100%] w-1/2',
                index === SCHEDULE.length - 1 && 'mr-[100%] w-1/2'
              )}
            >
              <div className="h-[6px] w-full bg-blue-40"></div>
            </div>

            <Text
              variant="heading-sm"
              color="inverse"
              className="ml-12 mt-8 md:ml-0 md:mt-0"
            >
              {item.month}
            </Text>
          </li>
        ))}
      </ol>
    </div>
  )
}
