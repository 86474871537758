export const PublikasiIcon = ({ width = 64, height = 64 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.6288 55.7878C55.4442 55.8838 55.2523 55.9724 55.0604 56.0462C54.8833 56.1274 54.6988 56.2012 54.5142 56.2602C54.4035 56.3045 54.2854 56.3414 54.1673 56.3709C53.9976 56.43 53.8204 56.4743 53.6507 56.5112C53.4883 56.5555 53.3185 56.5924 53.1414 56.6219C53.0971 56.6219 53.0528 56.6366 53.0012 56.644C52.8683 56.6662 52.7281 56.6883 52.5952 56.7031C52.4402 56.7252 52.2778 56.74 52.1228 56.7474C51.9161 56.7621 51.7095 56.7695 51.4954 56.7695H47.3252C48.174 56.7695 48.8604 56.0831 48.8604 55.2416V22.6695H59.179C60.0204 22.6695 60.7069 23.3559 60.7069 24.1974V47.5581C60.7069 50.599 59.2307 53.2931 56.9647 54.9759L55.6214 55.7952L55.6288 55.7878Z"
        fill="#2560E0"
      />
      <path
        d="M54.7282 56.1864C53.7244 56.5629 52.6468 56.7695 51.5102 56.7695H15.3066C12.9373 56.7695 10.7747 55.8691 9.14349 54.4002C7.27611 52.71 6.10254 50.2669 6.10254 47.5507V11.0593C6.10254 10.2179 6.78897 9.53143 7.6304 9.53143H47.3473C48.1961 9.53143 48.8825 10.2179 48.8825 11.0593V47.6024C48.8825 51.5143 51.3109 54.8431 54.743 56.1864H54.7282Z"
        fill="#CAF5FF"
      />
      <path
        d="M55.0604 56.0463C55.2523 55.9725 55.4442 55.8839 55.6287 55.7879C53.2594 55.906 53.2963 46.8349 53.2963 44.2294V22.6622H48.8678V55.2418C48.8678 56.0832 48.1813 56.7696 47.3325 56.7696H51.5028C51.7168 56.7696 51.9235 56.7696 52.1301 56.7475C52.2851 56.7475 52.4475 56.7253 52.6025 56.7032C52.7354 56.6884 52.8756 56.6663 53.0085 56.6441C53.0601 56.6441 53.1044 56.6294 53.1487 56.622C53.3259 56.5925 53.4956 56.5556 53.658 56.5113C53.8351 56.4744 54.0049 56.4227 54.1747 56.371C54.2928 56.3415 54.4109 56.3046 54.5216 56.2603C54.7061 56.1939 54.8906 56.1275 55.0678 56.0463H55.0604Z"
        fill="#0E43C1"
      />
      <path
        d="M54.7283 56.1865C53.7245 56.563 52.6469 56.7696 51.5102 56.7696H15.3066C12.9374 56.7696 10.7747 55.8691 9.14355 54.4003C34.9621 46.178 45.4357 24.751 48.8752 15.3403V47.6025C48.8752 48.6137 49.045 49.5806 49.3476 50.4884C50.2038 53.1013 52.1893 55.1901 54.7357 56.1865H54.7283Z"
        fill="#9ADFEF"
      />
      <path
        d="M54.7282 56.1864C53.7244 56.5629 52.6468 56.7695 51.5101 56.7695H24.2744C24.2744 56.7695 41.4277 56.7695 49.3475 50.4883C50.2037 53.1012 52.1892 55.19 54.7356 56.1864H54.7282Z"
        fill="#5ABDE5"
      />
      <path
        d="M15.1221 9.53143C6.83331 10.3138 6.15426 17.1338 6.15426 17.1338H6.09521V11.0593C6.09521 10.2179 6.78164 9.53143 7.62307 9.53143H15.1221Z"
        fill="#E4F4F4"
      />
      <path
        d="M22.3039 22.581V29.652L19.536 26.9727C19.381 26.825 19.1965 26.7512 18.9972 26.7439C18.7758 26.7291 18.5543 26.8103 18.3846 26.9727L16.2441 29.0467L13.0186 25.9172C12.8858 25.7917 12.7234 25.7031 12.561 25.6736C12.2362 25.5924 11.8746 25.6736 11.6236 25.9246L9.04028 28.4341V22.5884C9.04028 21.9241 9.59385 21.3853 10.2803 21.3853H21.0639C21.7503 21.3853 22.3039 21.9241 22.3039 22.5884V22.581Z"
        fill="#6BBDF7"
      />
      <path
        d="M16.303 26.2051C17.1672 26.2051 17.8678 25.5244 17.8678 24.6846C17.8678 23.8449 17.1672 23.1642 16.303 23.1642C15.4388 23.1642 14.7383 23.8449 14.7383 24.6846C14.7383 25.5244 15.4388 26.2051 16.303 26.2051Z"
        fill="#FCD222"
      />
      <path
        d="M18.9898 31.6965L12.3617 31.6669L10.1769 31.6522C9.54219 31.6079 9.04028 31.0912 9.04028 30.4638V28.4267L11.6236 25.9172C11.8746 25.6662 12.2362 25.585 12.561 25.6662C12.5019 25.6515 12.4503 25.6441 12.3912 25.6441L13.0112 28.7884L13.7862 28.5374L14.5834 31.4234L16.2736 29.0541L16.2515 29.0319L18.3919 26.9579C18.5543 26.7955 18.7831 26.7217 19.0046 26.7291L19.5286 29.4084L20.1929 29.1943L20.8719 31.6596H18.9603L18.9898 31.6891V31.6965Z"
        fill="#007B00"
      />
      <path
        d="M16.2664 29.0616L14.5761 31.4309L13.779 28.5376L13.004 28.7885L12.3914 25.6443C12.6275 25.6516 12.8416 25.7328 13.0187 25.91L16.2664 29.0616ZM19.5361 26.9654C19.3811 26.8178 19.1966 26.744 18.9973 26.7366L19.5214 29.4159L20.1856 29.2019L20.8647 31.6671H21.064C21.7504 31.6671 22.304 31.1283 22.304 30.464V29.6447L19.5361 26.9654Z"
        fill="#012B0A"
      />
      <path
        d="M45.1996 14.7424H9.71933C9.3443 14.7424 9.04028 15.0464 9.04028 15.4214V17.7538C9.04028 18.1288 9.3443 18.4328 9.71933 18.4328H45.1996C45.5746 18.4328 45.8786 18.1288 45.8786 17.7538V15.4214C45.8786 15.0464 45.5746 14.7424 45.1996 14.7424Z"
        fill="#164C9B"
      />
      <path
        d="M45.1996 45.7129H9.71933C9.3429 45.7129 9.04028 46.0155 9.04028 46.392V47.9862C9.04028 48.3627 9.3429 48.6653 9.71933 48.6653H45.1996C45.576 48.6653 45.8786 48.3627 45.8786 47.9862V46.392C45.8786 46.0155 45.576 45.7129 45.1996 45.7129ZM45.1996 39.8155H9.71933C9.3429 39.8155 9.04028 40.1181 9.04028 40.4946V42.0889C9.04028 42.4653 9.3429 42.7679 9.71933 42.7679H45.1996C45.576 42.7679 45.8786 42.4653 45.8786 42.0889V40.4946C45.8786 40.1181 45.576 39.8155 45.1996 39.8155ZM45.1996 33.9181H9.71933C9.3429 33.9181 9.04028 34.2208 9.04028 34.5972V36.1915C9.04028 36.5679 9.3429 36.8705 9.71933 36.8705H45.1996C45.576 36.8705 45.8786 36.5679 45.8786 36.1915V34.5972C45.8786 34.2208 45.576 33.9181 45.1996 33.9181ZM45.2439 26.5446H26.6143C26.2674 26.5446 25.9869 26.8472 25.9869 27.2236V28.8179C25.9869 29.1943 26.2674 29.497 26.6143 29.497H45.2439C45.5908 29.497 45.8712 29.1943 45.8712 28.8179V27.2236C45.8712 26.8472 45.5908 26.5446 45.2439 26.5446ZM37.0141 21.3853H26.6143C26.2674 21.3853 25.9869 21.6879 25.9869 22.0643V23.6586C25.9869 24.035 26.2674 24.3377 26.6143 24.3377H37.0141C37.361 24.3377 37.6415 24.035 37.6415 23.6586V22.0643C37.6415 21.6879 37.361 21.3853 37.0141 21.3853Z"
        fill="#3C79B0"
      />
      <path
        d="M45.8785 27.2162V28.8104C45.8785 29.1869 45.5759 29.4895 45.1994 29.4895H41.7083C42.3725 28.4931 42.9852 27.504 43.5609 26.5371H45.2068C45.5833 26.5371 45.8859 26.8397 45.8859 27.2162H45.8785ZM45.1994 33.9107H38.5344C37.7594 34.8923 36.9402 35.8814 36.084 36.8631H45.2068C45.5833 36.8631 45.8859 36.5604 45.8859 36.184V34.5897C45.8859 34.2133 45.5833 33.9107 45.2068 33.9107H45.1994ZM45.1994 39.8081H33.2866C32.2754 40.8119 31.2199 41.7935 30.098 42.7604H45.1994C45.5759 42.7604 45.8785 42.4578 45.8785 42.0814V40.4871C45.8785 40.1107 45.5759 39.8081 45.1994 39.8081ZM45.1994 45.7054H26.378C24.9683 46.7314 23.4921 47.7204 21.9199 48.6578H45.1994C45.5759 48.6578 45.8785 48.3552 45.8785 47.9788V46.3845C45.8785 46.0081 45.5759 45.7054 45.1994 45.7054Z"
        fill="#164C9B"
      />
      <path
        d="M6.53718 12.9676C6.53718 13.4095 6.17146 13.76 5.73718 13.7676L2.25527 13.7524C1.82099 13.7524 1.47051 13.3943 1.46289 12.96C1.46289 12.5181 1.8286 12.1676 2.26289 12.16L5.7448 12.1752C6.17908 12.1752 6.52956 12.5333 6.53718 12.9676Z"
        fill="#EFB003"
      />
      <path
        d="M7.75615 10.0648C7.75615 10.2705 7.67996 10.4686 7.51996 10.6286C7.21519 10.9333 6.70472 10.9333 6.39996 10.621L3.94662 8.14478C3.64186 7.84002 3.64186 7.32954 3.95424 7.02478C4.259 6.72002 4.76948 6.72002 5.07424 7.0324L7.52757 9.50859C7.67996 9.66097 7.75615 9.85906 7.75615 10.0648Z"
        fill="#EFB003"
      />
      <path
        d="M10.6818 5.40191L10.659 8.89143C10.659 9.32572 10.2933 9.68381 9.8666 9.68381C9.43993 9.68381 9.08184 9.32572 9.07422 8.89143L9.09708 5.40191C9.09708 4.96762 9.46279 4.60953 9.88946 4.60953C10.3237 4.60953 10.6742 4.96762 10.6818 5.40191Z"
        fill="#EFB003"
      />
    </svg>
  )
}
