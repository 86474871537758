'use client'

import { Icon } from '@wartek-id/icon'

const ARIA_LABEL = {
  NEXT: 'Next Slide',
  PREV: 'Previous Slide',
}

export const ArrowButton = ({ type, onClick }) => {
  return (
    <button
      type="button"
      className="flex h-[26px] w-[26px] items-center justify-center rounded border border-default text-icon-default focus:ring-2 focus:ring-blue-70 focus:ring-offset-2"
      data-testid={`btn-${type.toLowerCase()}-slide`}
      aria-label={ARIA_LABEL[type]}
      onClick={onClick}
    >
      {type === 'PREV' ? (
        <Icon color="inherit" fontSize="sm">
          chevron_left
        </Icon>
      ) : (
        <Icon color="inherit" fontSize="sm">
          chevron_right
        </Icon>
      )}
    </button>
  )
}

export const CustomArrowButton = ({ onPrev, onNext }) => {
  return (
    <div className="mr-4 flex items-center gap-2">
      <ArrowButton type="PREV" onClick={onPrev} />
      <ArrowButton type="NEXT" onClick={onNext} />
    </div>
  )
}
