'use client'

import styles from './schedule.module.css'

import clsx from 'clsx'
import { Fragment } from 'react'

import { useMedia } from '@wartek-id/react-utils'
import { Text } from '@wartek-id/text'

type ScheduleItem = {
  month: string
  isEmpty: boolean
  texts?: string[]
}

const JANUARI = {
  month: 'Jan',
  isEmpty: true,
}

const FEBRUARI = {
  month: 'Februari',
  isEmpty: false,
  texts: ['Rilis Buku APK dan APM', 'Rilis Buku Statistik Persekolahan'],
}

const MARET = {
  month: 'Mar',
  isEmpty: true,
}

const APRIL = {
  month: 'April',
  isEmpty: false,
  texts: [
    'Rilis Buku Pendekatan IPM dengan APK-APM',
    'Rilis Buku Ikhtisar Data Pendidikan',
  ],
}

const MEI = {
  month: 'Mei',
  isEmpty: false,
  texts: ['Rilis Buku Indonesia Education Statistics in Brief'],
}

const JUNI = {
  month: 'Juni',
  isEmpty: false,
  texts: ['Rilis Buku Sanitasi Persekolahan'],
}

const JULI = {
  month: 'Juli',
  isEmpty: false,
  texts: ['Rilis Buku Statistik dan Indikator Pendidikan Berwawasan Gender'],
}

const AGU = {
  month: 'Agu',
  isEmpty: true,
}

const SEPT = {
  month: 'Sept',
  isEmpty: true,
}

const OKT = {
  month: 'Oktober',
  isEmpty: false,
  texts: ['Rilis Buku Perkembangan Data Pendidikan'],
}

const NOV = {
  month: 'Nov',
  isEmpty: true,
}

const DES = {
  month: 'Des',
  isEmpty: true,
}

const SCHEDULE_DESKTOP = [
  [JANUARI, FEBRUARI, MARET, APRIL, MEI],
  [JUNI, JULI, AGU, SEPT, OKT, NOV, DES],
]

const SCHEDULE_MOBILE = [
  [JANUARI, FEBRUARI],
  [MARET, APRIL],
  [MEI],
  [JUNI],
  [JULI, AGU],
  [SEPT, OKT],
  [NOV, DES],
]

export const SchedulePublication = () => {
  const isMd = useMedia('(min-width: 768px)', false)
  const SCHEDULE = isMd ? SCHEDULE_DESKTOP : SCHEDULE_MOBILE

  return (
    <div
      className={clsx(
        'relative space-y-6 overflow-hidden rounded-lg bg-blue-90 px-4 py-8',
        styles.patternAdd,
        isMd ? styles.rotate45 : ''
      )}
    >
      <Text variant="display-sm" color="inverse">
        Jadwal Terbit Publikasi
      </Text>

      {SCHEDULE.map((scheduleArray, indexArr) => (
        <Fragment key={indexArr}>
          <ol className="relative flex w-full gap-x-2 gap-y-2 overflow-hidden md:gap-y-12">
            {scheduleArray.map((item: ScheduleItem) => (
              <li
                key={item.month}
                className={clsx(
                  'relative flex min-h-[150px] flex-col justify-between gap-8 rounded p-4 ',
                  item.isEmpty
                    ? 'items-center bg-blue-60 text-white'
                    : 'w-full items-start overflow-auto border-l-4 border-orange-20 bg-white md:overflow-hidden'
                )}
              >
                {!item.isEmpty && item.texts && item.texts.length > 0 ? (
                  <div className="flex gap-2">
                    {item.texts.map((text) => (
                      <div
                        key={text}
                        className={clsx(
                          'relative rounded-r rounded-tl bg-blue-10 p-4',
                          styles.withTopLeftArrow,
                          item.texts && item.texts.length === 1
                            ? 'md:max-w-[250px] lg:max-w-[100%]'
                            : 'max-w-[100px] md:max-w-[50%]'
                        )}
                      >
                        <Text variant="helper-bold">{text}</Text>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div />
                )}
                <Text
                  variant="heading-sm"
                  color={item.isEmpty ? 'inverse' : 'default'}
                >
                  {item.month}
                </Text>
              </li>
            ))}
          </ol>
          {indexArr !== SCHEDULE.length - 1 && (
            <div className="relative !-mb-[15px] !mt-[5px] hidden md:block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="42"
                viewBox="0 0 925 42"
                fill="none"
              >
                <path
                  d="M925 1C925 0.447715 924.552 0 924 0C923.448 0 923 0.447715 923 1H925ZM7.29289 41.7071C7.68342 42.0976 8.31658 42.0976 8.70711 41.7071L15.0711 35.3431C15.4616 34.9526 15.4616 34.3195 15.0711 33.9289C14.6805 33.5384 14.0474 33.5384 13.6569 33.9289L8 39.5858L2.34315 33.9289C1.95262 33.5384 1.31946 33.5384 0.928932 33.9289C0.538408 34.3195 0.538408 34.9526 0.928932 35.3431L7.29289 41.7071ZM904 20H28V22H904V20ZM28 20C16.402 20 7 29.402 7 41H9C9 30.5066 17.5066 22 28 22V20ZM923 1C923 11.4934 914.493 20 904 20V22C915.598 22 925 12.598 925 1H923Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}
