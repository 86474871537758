import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'

import { Container } from '@/components/Container'

const BENEFITS = [
  {
    title: 'Platform Sentral dan Resmi',
    description:
      'Pintu yang resmi menyediakan data induk pendidikan, kebudayaan, dan kebahasaan di Indonesia yang akuntabel sesuai arahan KepMen Satu Data dan Permendikbud Ristek Nomor 31 Tahun 2022.',
  },
  {
    title: 'Terintregasi Dalam Satu Platform',
    description:
      'Platform yang menggabungkan data dari berbagai sumber, menyeluruh, mudah diakses dan dibagipakaikan.',
  },
  {
    title: 'Diperbaharui Sesuai Kondisi Di Lapangan dan Terkini',
    description:
      'Data yang ditampilkan adalah data yang tepat dan terkini, sehingga selalu akurat untuk digunakan.',
  },
]
export const BenefitSection = () => {
  return (
    <section id="benefit-section" className="bg-white pt-8">
      <Container className="space-y-6">
        <Text variant="display-sm">
          Mengapa Menggunakan Portal Data Kemendikbudristek?
        </Text>
        <div className="flex flex-col gap-6 md:flex-row">
          {BENEFITS.map((item) => (
            <div
              key={item.title}
              className="flex-1 space-y-6 rounded-lg border border-disabled bg-blue-5 p-4 md:max-w-[380px]"
            >
              <Text variant="heading-md">{item.title}</Text>
              <Separator
                style={{
                  width: 40,
                  height: 2,
                }}
              />
              <Text variant="body-sm" color="subdued">
                {item.description}
              </Text>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
