'use client'

import styles from './custom-button-select.module.css'

import clsx from 'clsx'
import { forwardRef, PropsWithChildren } from 'react'

type Ref = HTMLButtonElement

export const CustomButtonSelect = forwardRef<
  Ref,
  PropsWithChildren<{
    testId?: string
    className?: string
    fullWidth?: boolean
    withoutRightBorder?: boolean
  }>
>(
  (
    {
      children,
      testId = 'btn-select',
      className = '',
      fullWidth = false,
      withoutRightBorder = false,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        data-testid={testId}
        className={clsx(
          styles.customBtn,
          fullWidth ? 'w-full' : '',
          withoutRightBorder ? styles.customBtnBr0 : '',
          className
        )}
        {...rest}
      >
        {children}
      </button>
    )
  }
)

CustomButtonSelect.displayName = 'CustomButtonSelect'
