'use client'

import { Fragment } from 'react'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Icon } from '@wartek-id/icon'

import { CustomButtonSelect } from '@/components/CustomButtonSelect/CustomButtonSelect'
import {
  DropdownMenuContent,
  DropdownMenuRadioItem,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/components/CustomDropdown'

import { getSearchGroups, SEARCH_GROUPS_BY_ID } from '../constants'

export function HomeSearchGroupSelect({ value, onChange }) {
  const searchGroups = getSearchGroups({ withPtk: true })

  return (
    <div className="w-[70px] md:w-[150px]">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <CustomButtonSelect
            fullWidth
            testId="btn-search-group"
            className=""
            withoutRightBorder={true}
          >
            <span className="overflow-hidden text-clip whitespace-nowrap lg:max-w-[70px]">
              {value ? SEARCH_GROUPS_BY_ID[value] : 'Dataset'}
            </span>
            <span className="absolute right-0 top-0 flex h-full flex-col justify-center pr-2">
              <Icon className="">expand_more</Icon>
            </span>
          </CustomButtonSelect>
        </DropdownMenu.Trigger>

        <DropdownMenuContent>
          {searchGroups.map((item) => (
            <Fragment key={item.id}>
              {typeof item.children !== 'undefined' &&
              item.children.length > 0 ? (
                <DropdownMenu.Sub key={item.id}>
                  <DropdownMenuSubTrigger
                    data-testid={`item-${item.id}`}
                    withIcon={Boolean(
                      typeof item.children !== 'undefined' &&
                        item.children.length > 0
                    )}
                  >
                    {item.name}
                  </DropdownMenuSubTrigger>
                  <DropdownMenu.Portal>
                    <DropdownMenuSubContent sideOffset={2} alignOffset={-5}>
                      <DropdownMenu.RadioGroup
                        value={value}
                        onValueChange={(newValue) => {
                          onChange(newValue)
                        }}
                      >
                        {(item?.children || []).map((child) => (
                          <DropdownMenuRadioItem
                            key={child.id}
                            value={child.id}
                            data-testid={`child-item-${child.id}`}
                          >
                            {child.name}
                          </DropdownMenuRadioItem>
                        ))}
                      </DropdownMenu.RadioGroup>
                    </DropdownMenuSubContent>
                  </DropdownMenu.Portal>
                </DropdownMenu.Sub>
              ) : (
                <DropdownMenu.Item
                  data-testid={`item-${item.id}`}
                  className="flex cursor-default select-none items-center justify-between rounded px-2 py-2.5 text-sm outline-none focus:bg-gray-5 data-[state=open]:bg-gray-5"
                  onClick={() => {
                    onChange(item.id)
                  }}
                >
                  {item.name}
                  {item.id === value && (
                    <Icon className="ml-1" fontSize="sm" color="informational">
                      check
                    </Icon>
                  )}
                </DropdownMenu.Item>
              )}
            </Fragment>
          ))}
        </DropdownMenuContent>
      </DropdownMenu.Root>
    </div>
  )
}
