'use client'

import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'

import {
  trackSearchBeranda,
  trackViewBeranda,
} from '@/utils/tracker/modules/beranda'
import { TrackerSection } from '@/utils/tracker/shared'
import { useProductionRedirection } from '@/utils/useProductionRedirection'

import { UncontrolledSearchBox } from '@/components/CommonSearchBox/UncontrolledSearchBox'

import { BenefitSection } from './components/BenefitSection'
import { DataAvailable } from './components/DataAvailable'
import { HeroSection } from './components/HeroSection/HeroSection'
import { HomeSearchGroupSelect } from './components/HomeSearchGroupSelect'
import { ScheduleContainer } from './components/ReleaseSchedule/ScheduleContainer'
import { getSearchTargetAddressById, SEARCH_GROUPS_BY_ID } from './constants'

export default function HomePage() {
  const router = useRouter()
  const mountedRef = useRef<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [selectedGroup, setSelectedGroup] = useState<string>('DATASET')
  useProductionRedirection()

  const handleSubmitSearch = (newKeyword) => {
    setKeyword(newKeyword)
    if (newKeyword) {
      let subSection = ''
      if (selectedGroup === 'DATA_INDUK_SATPEN') {
        subSection = TrackerSection.SATPEN
      }

      if (selectedGroup === 'DATA_INDUK_YAYASAN') {
        subSection = TrackerSection.YAYASAN
      }

      if (selectedGroup === 'DATA_INDUK_PTK') {
        subSection = TrackerSection.PTK
      }

      trackSearchBeranda({
        keyword: newKeyword,
        section: SEARCH_GROUPS_BY_ID[selectedGroup],
        sub_section: subSection,
      })

      const searchParams = getSearchTargetAddressById(selectedGroup, newKeyword)
      router.replace(searchParams)
    }
  }

  useEffect(() => {
    if (!mountedRef.current) {
      trackViewBeranda()
      mountedRef.current = true
    }
  }, [])

  return (
    <>
      <HeroSection />

      <div className="h-[6px] w-full bg-orange-20"></div>

      <section className="flex flex-col bg-gray-10" id="search-section">
        <UncontrolledSearchBox
          initialValue={keyword}
          onSubmit={handleSubmitSearch}
          leftSection={
            <HomeSearchGroupSelect
              value={selectedGroup}
              onChange={(newValue) => {
                setSelectedGroup(newValue)
              }}
            />
          }
        />
      </section>

      <BenefitSection />
      <DataAvailable />
      <ScheduleContainer />
    </>
  )
}
