import styles from './schedule.module.css'

import clsx from 'clsx'

import { Text } from '@wartek-id/text'

export const DotIndicator = ({
  text,
  isActive = false,
}: {
  text: string
  isActive?: boolean
}) => {
  return (
    <div className="relative z-[1] mt-24 inline-block h-[24px] w-[24px] rounded-full bg-orange-20">
      <div
        className={clsx(
          styles.withArrow,
          'absolute bottom-[-150%] left-12 z-10 mb-4 flex min-h-[69px] w-[215px] items-center justify-start rounded px-4 py-2 text-left md:bottom-full md:left-[-260%] md:w-[150px] md:justify-center md:text-center',
          isActive ? 'is--active bg-orange-20' : 'bg-white'
        )}
      >
        <Text variant="body-sm-bold">{text}</Text>
      </div>
    </div>
  )
}
