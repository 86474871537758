import { useRef } from 'react'
import Slider from 'react-slick'

import { Text } from '@wartek-id/text'

import { Container } from '@/components/Container'

import { CustomArrowButton } from './CustomCarousel/CustomArrowButton'
import { CustomCarousel } from './CustomCarousel/CustomCarousel'

export const DataAvailable = () => {
  const sliderRef = useRef<Slider>(null)

  const handleNext = () => {
    // @ts-ignore
    sliderRef.current?.slickNext()
  }

  const handlePrevious = () => {
    // @ts-ignore
    sliderRef.current?.slickPrev()
  }

  return (
    <section id="data-available-section" className="pb-[13rem] pt-8 lg:pb-56">
      <Container className="space-y-6">
        <div className="flex items-start justify-between">
          <Text variant="display-sm">Data Apa Saja Yang Tersedia?</Text>
          <CustomArrowButton onPrev={handlePrevious} onNext={handleNext} />
        </div>
        <CustomCarousel sliderRef={sliderRef} withPtk={true} />
      </Container>
    </section>
  )
}
