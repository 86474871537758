'use client'

import React from 'react'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Icon } from '@wartek-id/icon'

import { cn } from '@/utils/styling'

export const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenu.Content>
>(({ className, sideOffset = 5, ...props }, ref) => (
  <DropdownMenu.Portal>
    <DropdownMenu.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-10 min-w-[220px] rounded-md bg-white p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
        className
      )}
      {...props}
    />
  </DropdownMenu.Portal>
))
DropdownMenuContent.displayName = DropdownMenu.Content.displayName

export const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenu.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenu.SubContent
    ref={ref}
    className={cn(
      'z-10 min-w-[220px] rounded-md bg-white p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
      className
    )}
    {...props}
  />
))
DropdownMenuSubContent.displayName = DropdownMenu.SubContent.displayName

export const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenu.SubTrigger> & {
    inset?: boolean
    withIcon?: boolean
  }
>(({ className, inset, children, withIcon, ...props }, ref) => (
  <DropdownMenu.SubTrigger
    ref={ref}
    className={cn(
      'flex cursor-default select-none items-center justify-between rounded px-2 py-2.5 text-sm outline-none focus:bg-gray-5 data-[state=open]:bg-gray-5',
      inset && 'pl-8',
      className
    )}
    {...props}
  >
    {children}
    {withIcon ? <Icon>chevron_right</Icon> : null}
  </DropdownMenu.SubTrigger>
))
DropdownMenuSubTrigger.displayName = DropdownMenu.SubTrigger.displayName

export const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenu.Item> & {
    inset?: boolean
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenu.Item
    ref={ref}
    className={cn(
      'relative flex cursor-default select-none items-center rounded px-2 py-2.5 text-sm outline-none transition-colors focus:bg-gray-5 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      inset && 'pl-8',
      className
    )}
    {...props}
  />
))
DropdownMenuItem.displayName = DropdownMenu.Item.displayName

export const DropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenu.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenu.RadioItem
    ref={ref}
    className={cn(
      'focus:text-accent-foreground relative mb-1 flex cursor-default select-none items-center rounded-sm py-2.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-gray-5 data-[disabled]:pointer-events-none data-[state=checked]:bg-gray-5 data-[disabled]:opacity-50 data-[state=checked]:ring-2 data-[state=checked]:ring-blue-50',
      className
    )}
    {...props}
  >
    <span className="absolute left-2 mt-1 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenu.ItemIndicator>
        <Icon className="ml-1" fontSize="sm" color="informational">
          check
        </Icon>
      </DropdownMenu.ItemIndicator>
    </span>
    {children}
  </DropdownMenu.RadioItem>
))
DropdownMenuRadioItem.displayName = DropdownMenu.RadioItem.displayName
