export const DataIndukIcon = ({ width = 64, height = 64 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M56.457 58.4381C56.457 58.9867 56.0151 59.4286 55.4665 59.4286H49.676C50.7579 59.4286 51.6798 58.5524 51.7332 57.4781V57.4476H55.4665C56.0151 57.4476 56.457 57.8895 56.457 58.4381Z"
        fill="#012456"
      />
      <path
        d="M54.3619 7.23809L51.7257 57.4781C51.6724 58.5524 50.7505 59.4286 49.6685 59.4286H13.1276C12.0457 59.4286 11.2152 58.5524 11.2685 57.4781L13.9047 7.23809C13.9581 6.15619 14.88 5.28 15.9619 5.28H52.5104C53.5924 5.28 54.4228 6.15619 54.3619 7.23809Z"
        fill="#037F76"
      />
      <path
        d="M54.2933 8.55619L51.7257 57.4781C51.6724 58.5524 50.7505 59.4286 49.6686 59.4286H47.4895L49.5924 38.3924L52.5943 8.39619L54.3009 8.55619H54.2933Z"
        fill="#005E5E"
      />
      <path
        d="M52.9218 34.8343L51.7333 57.4781C51.6799 58.5524 50.758 59.4286 49.6761 59.4286H47.4971L49.5999 38.3924C50.4837 36.2362 51.1237 34.7581 51.2152 34.8343H52.9218Z"
        fill="#002E30"
      />
      <path
        d="M52.6551 7.23809L50.0189 57.4781C49.9656 58.5524 49.0437 59.4286 47.9694 59.4286H11.4894C10.4075 59.4286 9.57703 58.5524 9.63036 57.4781L12.2666 7.23809C12.3199 6.15619 13.2418 5.28 14.3237 5.28H50.8037C51.878 5.28 52.7085 6.15619 52.6475 7.23809H52.6551Z"
        fill="#32A592"
      />
      <path
        d="M25.4017 5.28C13.7751 8.56381 11.8398 15.5124 11.8398 15.5124L12.2741 7.23809C12.3275 6.15619 13.2494 5.28 14.3313 5.28H25.4017Z"
        fill="#46D3AA"
      />
      <path
        d="M52.5865 8.48L50.0189 57.4781C49.9656 58.5524 49.0437 59.4286 47.9694 59.4286H13.5618C13.9656 58.2781 14.3999 56.899 14.7351 55.9619L43.0703 42.8267L45.3942 41.7524L45.6151 40.5562L49.9275 17.0971L52.5789 8.48H52.5865Z"
        fill="#037F76"
      />
      <path
        d="M51.2076 34.8343L50.019 57.4781C49.9657 58.5524 49.0438 59.4286 47.9695 59.4286H27.3828C27.3828 59.4286 28.6933 56.2133 28.6552 55.9619C28.6552 55.7638 37.1047 48.1676 43.0704 42.8267C43.9923 42.0038 44.8533 41.2267 45.6152 40.5562C47.5581 38.8191 48.8533 37.661 48.8533 37.661C48.8533 37.661 49.2114 37.28 51.2076 34.8419V34.8343Z"
        fill="#005E5E"
      />
      <path
        d="M50.2246 11.5505L47.9846 54.2857C47.9388 55.2153 47.1465 55.9619 46.2246 55.9619H14.636C13.7141 55.9619 12.4417 55.1314 12.495 54.2019L14.6969 12.1448C14.7427 11.2152 15.9312 9.31049 16.8607 9.31049H48.4341C49.3636 9.31049 50.2703 10.621 50.2169 11.5505H50.2246Z"
        fill="#9EE1EA"
      />
      <path
        d="M49.9351 17.0972L47.9846 54.2857C47.9389 55.2152 47.1465 55.9619 46.2246 55.9619H14.7351C14.8418 55.6724 14.9484 55.3981 14.9484 55.3981C14.9484 55.3981 49.158 17.4629 49.3561 17.3714C49.6151 17.2495 49.9275 17.0972 49.9275 17.0972H49.9351Z"
        fill="#70B6DB"
      />
      <path
        d="M48.8533 37.6533L47.9847 54.2781C47.939 55.2076 47.1466 55.9543 46.2247 55.9543H28.6552L28.5562 55.3905L42.4609 52.5943L45.3257 49.2495L48.2971 37.6457H48.8533V37.6533Z"
        fill="#3C79B0"
      />
      <path
        d="M49.6913 10.979L47.4513 53.7219C47.4055 54.6514 46.6132 55.3981 45.6913 55.3981H14.1027C13.1808 55.3981 12.4646 54.6514 12.5179 53.7219L14.7579 10.979C14.8036 10.0571 15.596 9.30286 16.5179 9.30286H48.1065C49.0284 9.30286 49.7446 10.0571 49.6913 10.979Z"
        fill="#E4F4F4"
      />
      <path
        d="M41.1656 4.57144H24.7237C24.1751 4.57144 23.6799 4.85335 23.3827 5.28001C23.2227 5.49335 23.1161 5.75239 23.0932 6.02668C23.0856 6.06477 23.078 6.10287 23.078 6.14097L22.918 9.15049C22.8723 10.0191 23.5427 10.72 24.4037 10.72H26.3237L26.3085 10.9867C26.2704 11.7714 26.7351 12.381 27.3675 12.381H37.7142C38.3465 12.381 38.8723 11.7638 38.918 10.9867L38.9332 10.72H40.8532C41.7142 10.72 42.4532 10.0191 42.4989 9.15049L42.6589 6.14097C42.7046 5.28001 42.0342 4.57144 41.1732 4.57144H41.1656ZM41.7218 9.15049C41.6989 9.59239 41.318 9.95049 40.8837 9.95049H38.1942L38.1408 10.9867C38.1256 11.3448 37.9123 11.6191 37.7446 11.6191H27.398C27.2304 11.6191 27.0475 11.3524 27.0704 10.9867L27.1237 9.95049H24.4342C23.9999 9.95049 23.657 9.60001 23.6799 9.15049L23.8399 6.14097C23.8399 5.98097 23.9008 5.8362 23.9923 5.7143C24.1446 5.49335 24.4037 5.34858 24.678 5.34858H41.1199C41.5542 5.34858 41.897 5.70668 41.8742 6.14858L41.7142 9.15811L41.7218 9.15049Z"
        fill="#164C9B"
      />
      <path
        d="M25.4019 5.28C25.3333 5.30286 25.2647 5.3181 25.1886 5.34096H24.6781C24.4038 5.34096 24.1371 5.48572 23.9924 5.70667C23.68 5.80572 23.3752 5.91238 23.0857 6.01905C23.1162 5.75238 23.2228 5.48572 23.3752 5.27238H25.4019V5.28Z"
        fill="#3C79B0"
      />
      <path
        d="M40.8228 4.57143H24.3809C23.5581 4.57143 22.8495 5.21143 22.7504 6.01143C22.7428 6.04953 22.7352 6.09524 22.7352 6.13334L22.5752 9.14286C22.5295 10.0114 23.2 10.7124 24.0609 10.7124H25.9809L25.9657 10.9791C25.9276 11.7638 26.3923 12.3733 27.0247 12.3733H37.3714C38.0038 12.3733 38.5295 11.7562 38.5752 10.9791L38.5904 10.7124H40.5104C41.3714 10.7124 42.1104 10.0114 42.1562 9.14286L42.3162 6.13334C42.3619 5.27238 41.6914 4.56381 40.8304 4.56381L40.8228 4.57143ZM41.379 9.1581C41.3562 9.6 40.9752 9.9581 40.5409 9.9581H37.8514L37.7981 10.9943C37.7828 11.3524 37.5695 11.6267 37.4019 11.6267H27.0552C26.8876 11.6267 26.7047 11.36 26.7276 10.9943L26.7809 9.9581H24.0914C23.6571 9.9581 23.3143 9.60762 23.3371 9.1581L23.4971 6.14857C23.5047 5.97334 23.5733 5.81334 23.68 5.68381C23.8323 5.48572 24.0762 5.35619 24.3352 5.35619H40.7771C41.2114 5.35619 41.5543 5.71429 41.5314 6.15619L41.3714 9.16572H41.379V9.1581Z"
        fill="#70B6DB"
      />
      <path
        d="M49.3561 17.3714L47.4513 53.7219C47.4056 54.6514 46.6132 55.3981 45.6913 55.3981H14.9485C14.9485 55.3981 38.0571 48.739 41.3866 41.1962C44.7237 33.6457 49.3561 17.3714 49.3561 17.3714Z"
        fill="#BADEE2"
      />
      <path
        d="M48.2971 37.6533L47.4514 53.7219C47.4057 54.6514 46.6133 55.3981 45.6914 55.3981H28.5562C28.5562 55.3981 40.0838 51.0476 42.7885 49.0895C45.4933 47.1238 48.2971 37.661 48.2971 37.661V37.6533Z"
        fill="#80B8BF"
      />
      <path
        d="M27.6264 4.57144C26.5826 4.80001 25.615 5.05144 24.7083 5.34097H24.3426C24.0836 5.34097 23.8398 5.47049 23.6874 5.66858C23.3674 5.77525 23.055 5.88954 22.7502 6.01144C22.8569 5.20382 23.5655 4.57144 24.3807 4.57144H27.6264Z"
        fill="#9EE1EA"
      />
      <path
        d="M32.1522 16.9752C32.038 16.9752 31.9465 16.8838 31.9465 16.7695V14.179C31.9465 14.0648 32.038 13.9733 32.1522 13.9733C32.2665 13.9733 32.358 14.0648 32.358 14.179V16.7695C32.358 16.8838 32.2665 16.9752 32.1522 16.9752Z"
        fill="#540C00"
      />
      <path
        d="M35.1845 15.4514C34.8036 15.4971 34.2626 15.3067 33.7674 15.0248C33.3179 14.7581 32.5483 14.9562 32.3274 15.0019V14.1029C32.3274 14.1029 33.2493 13.8057 33.7674 14.1029C34.2626 14.4 35.0093 14.4838 35.0855 14.4686L35.116 14.499C35.1693 14.5981 35.1845 15.0248 35.1693 15.4438H35.1769L35.1845 15.4514Z"
        fill="#CE0000"
      />
      <path
        d="M35.1846 15.4514V15.8933C35.177 16.2133 35.1618 16.4495 35.1618 16.4495C35.0703 16.5943 34.6894 16.4571 34.4075 16.3505C34.2627 16.3048 34.1256 16.2286 33.9808 16.1676C33.9122 16.1371 33.8513 16.0991 33.7827 16.061C33.7522 16.0381 33.7294 16.0305 33.6837 16.0152C33.6608 16.0076 33.638 15.9924 33.5922 15.9848C33.4932 15.9543 33.4094 15.9391 33.3103 15.9391C33.1046 15.9162 32.8608 15.9391 32.6856 15.9695C32.5789 15.9771 32.4875 16 32.4113 16.0229C32.3884 16.0229 32.3808 16.0305 32.358 16.0305H32.3503V15.0019C32.5561 14.9562 33.3408 14.7581 33.7903 15.0248C34.2703 15.3067 34.8189 15.4971 35.2075 15.4514H35.1999H35.1846Z"
        fill="white"
      />
      <path
        d="M44.3274 20.6476L42.4836 17.3714C42.3922 17.2038 42.217 17.0971 42.0265 17.0971H32.6779C32.3884 17.0971 32.1522 17.3409 32.1522 17.6229C32.1522 17.3333 31.916 17.0971 31.6265 17.0971H22.2855C22.0951 17.0971 21.9198 17.2038 21.8284 17.3714L19.9846 20.6476C19.7789 20.9981 20.0379 21.4324 20.4417 21.4324H31.6265C31.9236 21.4324 32.1522 21.1962 32.1522 20.9067C32.1522 21.2038 32.396 21.4324 32.6779 21.4324H43.8627C44.2665 21.4324 44.5255 20.9981 44.3274 20.6476Z"
        fill="#CE0000"
      />
      <path
        d="M38.5599 21.4324H36.1523L32.1447 17.6609L28.1447 21.4324H25.7371C25.7371 21.4324 25.7523 21.4095 25.7599 21.4095L30.339 17.0971H31.6113C31.9085 17.0971 32.1371 17.3409 32.1371 17.6229C32.1371 17.3333 32.3809 17.0971 32.6628 17.0971H33.9428L38.5142 21.4095L38.5371 21.4324H38.5599Z"
        fill="#540C00"
      />
      <path
        d="M28.7085 21.4324V26.5143H21.9962C21.6228 26.5143 21.3257 26.2171 21.3257 25.8438V21.4324H28.7085Z"
        fill="#E8BB79"
      />
      <path
        d="M28.7083 21.4324H21.3179V22.301H28.7083V21.4324Z"
        fill="#D38E50"
      />
      <path
        d="M42.9866 21.4324V25.8438C42.9866 26.2171 42.6895 26.5143 42.3161 26.5143H35.6038V21.4324H42.9866Z"
        fill="#E8BB79"
      />
      <path
        d="M42.9942 21.4324H35.6038V22.301H42.9942V21.4324Z"
        fill="#D38E50"
      />
      <path
        d="M35.6037 20.3429V26.5143H28.7085V20.3429L32.1523 17.0895L35.6037 20.3429Z"
        fill="#F4DCA6"
      />
      <path
        d="M35.6037 20.6324V21.8667L32.1523 18.6133L28.7085 21.859V20.6324L32.1523 17.379L35.6037 20.6324Z"
        fill="#E8BB79"
      />
      <path
        d="M36.7541 22.0876C36.5941 22.0876 36.4341 22.0267 36.3045 21.9124L32.1521 17.9962L27.9998 21.9124C27.7407 22.1638 27.3217 22.1486 27.0779 21.8819C26.8264 21.6229 26.8417 21.2038 27.1083 20.96L31.7102 16.6248C31.9617 16.3886 32.3579 16.3886 32.6093 16.6248L37.2112 20.96C37.4702 21.2038 37.4855 21.6229 37.2417 21.8819C37.1121 22.019 36.9369 22.0876 36.7693 22.0876H36.7541Z"
        fill="#EF1616"
      />
      <path
        d="M28.1979 22.3009C28.1979 22.3009 28.2208 26.5143 26.8113 26.5143H28.7084V22.3009H28.1979Z"
        fill="#D38E50"
      />
      <path
        d="M42.9864 22.3009V25.8438C42.9864 26.2171 42.6893 26.5143 42.316 26.5143H41.0969C42.5064 26.5143 42.4836 22.3009 42.4836 22.3009H42.9864Z"
        fill="#D38E50"
      />
      <path
        d="M27.2685 23.2457H22.6742C22.5219 23.2457 22.3923 23.3752 22.3923 23.5276V24.419C22.3923 24.5714 22.5219 24.7009 22.6742 24.7009H27.2685C27.4209 24.7009 27.5504 24.5714 27.5504 24.419V23.5276C27.5504 23.3752 27.4209 23.2457 27.2685 23.2457Z"
        fill="#70B6DB"
      />
      <path
        d="M41.5923 23.2457H36.998C36.8456 23.2457 36.7161 23.3752 36.7161 23.5276V24.419C36.7161 24.5714 36.8456 24.7009 36.998 24.7009H41.5923C41.7446 24.7009 41.8742 24.5714 41.8742 24.419V23.5276C41.8742 23.3752 41.7446 23.2457 41.5923 23.2457Z"
        fill="#70B6DB"
      />
      <path
        d="M32.1522 22.1029C32.8151 22.1029 33.3484 21.5695 33.3484 20.9067C33.3484 20.2438 32.8151 19.7105 32.1522 19.7105C31.4894 19.7105 30.9561 20.2438 30.9561 20.9067C30.9561 21.5695 31.4894 22.1029 32.1522 22.1029Z"
        fill="#70B6DB"
      />
      <path
        d="M31.5123 23.9695H33.1123C33.2876 23.9695 33.4323 24.1143 33.4323 24.2895V26.5143H31.2V24.2895C31.2 24.1143 31.3447 23.9695 31.5199 23.9695H31.5123Z"
        fill="#7C3B00"
      />
      <path
        d="M46.3923 32.3505C46.3923 32.7695 46.217 33.1505 45.9427 33.4248C45.6685 33.7067 45.2875 33.8743 44.8685 33.8743H17.8513C17.0056 33.8743 16.3275 33.1886 16.3275 32.3505C16.3275 31.9314 16.4951 31.5505 16.7694 31.2762C17.0437 31.0019 17.4323 30.8267 17.8513 30.8267H44.8685C44.9827 30.8267 45.0894 30.8419 45.1961 30.8648C45.8818 31.0172 46.3923 31.6267 46.3923 32.3505ZM44.1904 39.6191H17.1732C16.7542 39.6191 16.3732 39.7867 16.0989 40.0686C15.817 40.3429 15.6494 40.7238 15.6494 41.1429C15.6494 41.981 16.3351 42.6667 17.1732 42.6667H44.1904C44.6094 42.6667 44.9904 42.4991 45.2647 42.2172C45.5466 41.9429 45.7142 41.5619 45.7142 41.1429C45.7142 40.3048 45.0285 39.6191 44.1904 39.6191Z"
        fill="#AE53C4"
      />
      <path
        d="M46.3921 32.3505C46.3921 32.7695 46.2169 33.1505 45.9426 33.4248C45.6683 33.7067 45.2874 33.8743 44.8683 33.8743H44.1826C44.5255 32.8838 44.8683 31.8705 45.1959 30.8648C45.8817 31.0171 46.3921 31.6267 46.3921 32.3505Z"
        fill="#8131AA"
      />
      <path
        d="M45.7142 41.1429C45.7142 41.5619 45.5466 41.9429 45.2647 42.2171C44.9904 42.499 44.6095 42.6667 44.1904 42.6667H40.4875C40.8685 42.179 41.1656 41.6914 41.3866 41.1962C41.6075 40.7086 41.8285 40.1752 42.0571 39.619H44.1904C45.0285 39.619 45.7142 40.3048 45.7142 41.1429Z"
        fill="#8131AA"
      />
      <path
        d="M45.036 49.9352C45.036 50.3543 44.8683 50.7352 44.594 51.0095C44.3121 51.2914 43.9312 51.4591 43.5121 51.4591H16.495C15.6569 51.4591 14.9712 50.7733 14.9712 49.9352C14.9712 49.5162 15.1464 49.1352 15.4207 48.861C15.695 48.5791 16.076 48.4114 16.495 48.4114H43.5121C44.3579 48.4114 45.036 49.0972 45.036 49.9352Z"
        fill="#8131AA"
      />
      <path
        d="M29.4321 33.8743H17.8512C17.0131 33.8743 16.3274 33.1886 16.3274 32.3505C16.3274 31.5124 17.0131 30.8267 17.8512 30.8267H29.4321C30.2702 30.8267 30.956 31.5124 30.956 32.3505C30.956 33.1886 30.2702 33.8743 29.4321 33.8743ZM35.6036 41.1429C35.6036 40.3048 34.9179 39.6191 34.0798 39.6191H17.1731C16.335 39.6191 15.6493 40.3048 15.6493 41.1429C15.6493 41.981 16.335 42.6667 17.1731 42.6667H34.0798C34.9179 42.6667 35.6036 41.981 35.6036 41.1429ZM35.1921 48.4114H16.495C16.076 48.4114 15.695 48.5791 15.4207 48.861C15.1464 49.1352 14.9712 49.5162 14.9712 49.9352C14.9712 50.7733 15.6569 51.4591 16.495 51.4591H35.1921C35.6112 51.4591 35.9921 51.2914 36.2664 51.0095C36.5407 50.7352 36.716 50.3543 36.716 49.9352C36.716 49.0972 36.0302 48.4114 35.1921 48.4114ZM43.5121 48.4114H43.4969C43.2607 48.6857 43.0245 48.9143 42.7883 49.0895C41.9274 49.7143 40.1902 50.5676 38.2093 51.4591H43.5121C43.9312 51.4591 44.3121 51.2914 44.594 51.0095C44.8683 50.7352 45.036 50.3543 45.036 49.9352C45.036 49.0972 44.3579 48.4114 43.5121 48.4114Z"
        fill="#581E96"
      />
      <path
        d="M36.716 49.9352C36.716 50.3543 36.5408 50.7352 36.2665 51.0095C35.9922 51.2914 35.6113 51.4591 35.1922 51.4591H26.1179C28.3198 50.5524 30.5979 49.5314 32.716 48.4114H35.1922C36.0303 48.4114 36.716 49.0972 36.716 49.9352Z"
        fill="#240872"
      />
      <path
        d="M57.6074 37.2648C56.9293 39.7944 55.2607 41.7982 53.1198 42.9868C51.0245 44.1525 48.495 44.5258 45.9807 43.8477C43.2074 43.101 41.0436 41.2115 39.8855 38.8344C41.1198 40.6401 42.9941 42.042 45.2874 42.6591C47.9693 43.3829 50.6969 42.8953 52.8607 41.5391C54.7884 40.3277 56.2741 38.4382 56.9141 36.0839C57.5541 33.7296 57.2417 31.5277 56.2741 29.5696C57.7674 31.7563 58.3464 34.5448 57.6074 37.2725V37.2648Z"
        fill="#012456"
      />
      <path
        d="M53.1122 42.9791C51.017 44.1448 48.4875 44.5182 45.9732 43.8401C43.1998 43.0934 41.036 41.2039 39.8779 38.8268C41.1122 40.6325 42.9865 42.0344 45.2798 42.6515C47.9617 43.3753 50.6894 42.8877 52.8532 41.5315C52.777 42.0496 52.7846 42.6668 53.1122 42.9791Z"
        fill="#002066"
      />
      <path
        d="M54.1636 30.7506C53.2417 29.4782 51.8855 28.4953 50.2398 28.0534C46.4684 27.0401 42.6055 29.2268 41.5998 32.9449C41.1655 34.5677 41.3408 36.2134 41.9884 37.6382C40.7998 35.9925 40.3351 33.8515 40.8989 31.7563C41.897 28.0458 45.7674 25.8515 49.5389 26.8649C51.6646 27.4363 53.3103 28.9068 54.156 30.7506H54.1636Z"
        fill="#03228E"
      />
      <path
        d="M55.0094 36.2211C54.0113 39.9315 50.1409 42.1258 46.3694 41.1049C44.5485 40.6172 43.0856 39.4668 42.1713 37.9887C42.0647 37.8134 41.958 37.6382 41.8666 37.4553C41.577 36.8915 41.3713 36.282 41.2494 35.6496C41.2037 35.3906 41.158 35.1315 41.1428 34.8649C41.1199 34.5449 41.1123 34.2172 41.1351 33.8896C41.1656 33.4477 41.2342 33.0058 41.3561 32.5715L41.417 32.3353C41.577 31.8096 41.7904 31.3068 42.0647 30.8496C43.6189 28.1906 46.8418 26.7963 50.0037 27.6496L50.1332 27.6877C50.6818 27.8401 51.1923 28.0611 51.657 28.3277C51.7332 28.3658 51.7942 28.4039 51.8628 28.4572C52.2589 28.6858 52.617 28.9601 52.9523 29.2649C53.1961 29.4782 53.4323 29.7296 53.638 29.981C53.8056 30.1791 53.9656 30.3925 54.1028 30.6134C54.4532 31.1468 54.7351 31.7258 54.9256 32.3353C54.9866 32.5258 55.0399 32.7239 55.078 32.922C55.3218 33.9734 55.3066 35.1011 55.0094 36.2211Z"
        fill="#E4F4F4"
      />
      <path
        d="M51.8626 28.4496L41.356 32.5715L41.4169 32.3353C41.5769 31.8096 41.7902 31.3068 42.0645 30.8496L50.1331 27.6877C50.6817 27.8401 51.1922 28.061 51.6569 28.3277C51.7331 28.3658 51.7941 28.4039 51.8626 28.4572V28.4496Z"
        fill="#CAF3F9"
      />
      <path
        d="M55.0702 32.922L42.1636 37.9963C42.0569 37.821 41.9502 37.6458 41.8588 37.463C41.5693 36.8991 41.3636 36.2896 41.2417 35.6572C41.196 35.3982 41.1502 35.1391 41.135 34.8725L53.6226 29.981C53.7902 30.1791 53.9502 30.3925 54.0874 30.6134C54.4379 31.1468 54.7198 31.7258 54.9102 32.3353C54.9712 32.5258 55.0245 32.7239 55.0626 32.922H55.0702Z"
        fill="#CAF3F9"
      />
      <path
        d="M48.1903 39.1544C50.8328 39.1544 52.975 37.0122 52.975 34.3696C52.975 31.7271 50.8328 29.5849 48.1903 29.5849C45.5477 29.5849 43.4055 31.7271 43.4055 34.3696C43.4055 37.0122 45.5477 39.1544 48.1903 39.1544Z"
        fill="#2DB24F"
      />
      <path
        d="M48.8381 29.6382L44.48 31.3525C45.5162 30.0877 47.1619 29.4096 48.8381 29.6382Z"
        fill="#51CE84"
      />
      <path
        d="M52.9295 33.7677L44.2971 37.1582C43.6495 36.2515 43.3219 35.1315 43.4286 33.981L51.4209 30.8496C52.2514 31.6039 52.7924 32.6477 52.9371 33.7677H52.9295Z"
        fill="#51CE84"
      />
      <path
        d="M47.0018 37.3715C46.8114 37.3715 46.6285 37.303 46.4837 37.1582L44.3199 34.9944C44.0304 34.7049 44.0304 34.2401 44.3199 33.9582C44.6094 33.6763 45.0742 33.6687 45.3561 33.9582L47.0018 35.6039L51.0171 31.5887C51.3066 31.2991 51.7714 31.2991 52.0533 31.5887C52.3352 31.8782 52.3428 32.343 52.0533 32.6249L47.5199 37.1582C47.3752 37.303 47.1847 37.3715 47.0018 37.3715Z"
        fill="white"
      />
      <path
        d="M50.1713 24.5487C45.097 23.1772 39.9008 26.1258 38.5523 31.1239C37.2037 36.122 40.2132 41.2877 45.2875 42.6591C50.3618 44.0306 55.558 41.082 56.9066 36.0763C58.2551 31.0706 55.238 25.9201 50.1713 24.5487ZM45.9123 40.3353C42.1408 39.322 39.9008 35.4744 40.9065 31.7563C41.9123 28.0382 45.7751 25.8515 49.5465 26.8649C53.318 27.8782 55.558 31.7258 54.5599 35.4363C53.5618 39.1468 49.6913 41.3487 45.9123 40.3353Z"
        fill="#266B96"
      />
      <path
        d="M55.6875 28.5334C56.7237 30.5372 57.0361 32.8991 56.4113 35.2229C55.0627 40.221 49.8589 43.1696 44.7923 41.8058C42.4304 41.1658 40.518 39.7106 39.2837 37.821C40.4646 40.1144 42.5904 41.9201 45.2951 42.6515C50.3694 44.0229 55.5656 41.0744 56.9142 36.0687C57.6304 33.402 57.1046 30.6896 55.6951 28.5258L55.6875 28.5334Z"
        fill="#094C89"
      />
      <path
        d="M50.1712 24.5487C45.0969 23.1772 39.9008 26.1258 38.5522 31.1239C37.8588 33.7068 38.3236 36.3353 39.6341 38.4534C38.5369 36.4191 38.1865 33.9963 38.8341 31.6039C40.1827 26.6058 45.3865 23.6572 50.4531 25.0287C52.876 25.6839 54.8341 27.2077 56.0684 29.1582C54.8646 26.9715 52.7998 25.2572 50.1788 24.5487H50.1712Z"
        fill="#4995B2"
      />
      <path
        d="M53.8133 46.202L51.8324 46.9334L51.3371 45.6382L51.2838 45.4934V45.4782L50.88 44.4191L50.7657 44.0991C50.659 43.8096 50.6971 43.4972 50.8571 43.261L50.9029 43.1925C50.9714 43.1163 51.0476 43.0401 51.139 42.9868C51.1619 42.9639 51.2 42.9487 51.2305 42.941C51.2609 42.9258 51.2914 42.9106 51.3219 42.9029L51.5276 42.8268C51.6724 42.7734 51.8171 42.7658 51.9543 42.7734C52.0076 42.781 52.0686 42.7887 52.1219 42.8039C52.4038 42.8801 52.64 43.0858 52.7543 43.3753L53.8362 46.202H53.8209H53.8133Z"
        fill="#76B9D3"
      />
      <path
        d="M52.5712 44.8382L51.2836 45.501L50.7579 44.1144C50.6512 43.8249 50.6893 43.5125 50.8493 43.2763L50.895 43.2077C50.9636 43.1315 51.0398 43.0553 51.1312 43.002C51.1541 42.9791 51.1922 42.9639 51.2227 42.9563C51.2531 42.941 51.2836 42.9258 51.3141 42.9182L51.5198 42.842C51.6646 42.7887 51.8093 42.781 51.9465 42.7887C51.9693 43.1772 52.0836 43.6801 52.5865 44.861L52.5712 44.8382Z"
        fill="#4995B2"
      />
      <path
        d="M52.1447 45.0668L51.3295 45.6382L51.2762 45.4934L50.7504 44.1068C50.5752 43.6496 50.7809 43.1468 51.2152 42.941C51.3447 43.3906 51.6419 44.5487 52.16 45.0668H52.1523H52.1447Z"
        fill="#266B96"
      />
      <path
        d="M51.9085 44.7544L51.4514 45.4477L51.2761 45.4782L50.7961 44.221C50.9028 44.1677 51.1847 44.8763 51.299 44.8153C51.4209 44.7468 51.7257 44.6934 51.9085 44.7544Z"
        fill="#094C89"
      />
      <path
        d="M58.9714 57.6077L57.379 58.1944L56.6019 58.4839L55.7866 58.781L55.6114 58.842L55.4895 58.5372L50.9485 46.682C50.8114 46.3163 50.8266 45.9201 50.9714 45.5849C51.0857 45.3334 51.2609 45.1049 51.4971 44.9449C51.5885 44.8839 51.6876 44.8306 51.8095 44.7849L52.3961 44.5715C52.4952 44.5334 52.5942 44.5106 52.6933 44.4953C53.3714 44.3963 54.0571 44.7772 54.3009 45.4401L58.9561 57.6001H58.9714V57.6077Z"
        fill="#4995B2"
      />
      <path
        d="M57.3712 58.1944L55.6036 58.8496L50.9407 46.682C50.6512 45.9201 51.0322 45.0744 51.8093 44.7925L52.396 44.5791C52.495 44.541 52.5941 44.5182 52.6931 44.5029C52.6931 44.5029 53.9579 50.781 57.5998 57.0744L57.3636 58.1944H57.3712Z"
        fill="#266B96"
      />
      <path
        d="M56.9903 57.9887L56.5865 58.4763L55.596 58.842L50.9331 46.6744C50.6893 46.0191 50.9331 45.3106 51.4817 44.9449L51.5198 44.9601C51.5198 44.9601 51.9998 49.341 56.9827 57.981L56.9903 57.9887Z"
        fill="#094C89"
      />
      <path
        d="M57.6533 59.261C58.5828 58.9258 59.1771 58.202 58.979 57.6458C58.7809 57.0896 57.859 56.9068 56.9295 57.242C56 57.5772 55.4057 58.301 55.6038 58.8572C55.8019 59.4134 56.7238 59.5963 57.6533 59.261Z"
        fill="#012456"
      />
    </svg>
  )
}
