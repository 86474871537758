import { Text } from '@wartek-id/text'

import { trackClickLinkInBeranda } from '@/utils/tracker/modules/beranda'

import { LinkWithTracker } from '@/components/LinkWithTracker'

export const CarouselItem = ({ item }) => {
  return (
    <div className="relative mb-2 mr-6 flex h-[250px] w-[90vw] flex-col space-y-8 rounded-lg border border-l-4 border-disabled border-l-orange-20 p-4 sm:w-[calc((100vw-2rem-2rem)/2-1rem)] sm:max-w-[360px] md:w-[calc((100vw-2rem-2rem)/3-1rem)] md:min-w-[300px]">
      <div className="flex items-center gap-2">
        {item.icon}
        <Text variant="heading-md" color="informational">
          {item.title}
        </Text>
      </div>
      <div>
        {item.items.map((subItem) => (
          <LinkWithTracker
            key={subItem.text}
            href={subItem.link}
            onClick={() => {
              trackClickLinkInBeranda({
                section: item.title,
                sub_section: subItem.text,
              })
            }}
            className="block w-full border-t py-2"
          >
            <Text>{subItem.text}</Text>
          </LinkWithTracker>
        ))}
      </div>
    </div>
  )
}
