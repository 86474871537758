'use client'

import clsx from 'clsx'
import { FormEvent, forwardRef, useRef } from 'react'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'
import { Input, InputGroup, InputLeftAddon } from '@wartek-id/input'

import { xssSafeString } from '@/utils/string'

import { Container } from '@/components/Container'

export interface UncontrolledSearchBoxProps {
  initialValue?: string
  placeholder?: string
  onSubmit?: (keyword: string) => void
  maxLength?: number
  leftSection?: React.ReactNode
}

type Ref = HTMLFormElement

export const UncontrolledSearchBox = forwardRef<
  Ref,
  UncontrolledSearchBoxProps
>(function Component(
  {
    initialValue,
    onSubmit,
    placeholder = `Tulis pencarian di sini`,
    leftSection,
    ...rest
  },
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const keyword = inputRef?.current?.value || ''
    const safeKeyword = xssSafeString(keyword)

    if (typeof onSubmit === 'function') {
      onSubmit?.(safeKeyword)
    }
  }

  return (
    <form
      className="flex flex-col bg-gray-10"
      id="search-box"
      onSubmit={handleSubmit}
      ref={ref}
      {...rest}
    >
      <Container className="py-6">
        <div className="flex items-center gap-2">
          <div className="flex w-full items-center">
            {leftSection ? leftSection : null}
            <InputGroup>
              <InputLeftAddon>
                <Icon color="default">search</Icon>
              </InputLeftAddon>
              <Input
                id="txt-search"
                data-testid="txt-search"
                placeholder={placeholder}
                name="keyword"
                defaultValue={initialValue}
                ref={inputRef}
                clearable
                className={clsx(leftSection ? '!rounded-l-none' : '')}
              />
            </InputGroup>
          </div>
          <Button
            type="submit"
            size="lg"
            data-testid="btn-search"
            className="!py-[11px]"
          >
            Cari
          </Button>
        </div>
      </Container>
    </form>
  )
})
