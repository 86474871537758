'use client'

import 'slick-carousel/slick/slick.css'
import styles from './slider.module.css'

import Slider from 'react-slick'

import { DataIndukIcon } from '@/components/Icons/DataIndukIcon'
import { DatasetIcon } from '@/components/Icons/DatasetIcon'
import { PlatfromPrioritasIcon } from '@/components/Icons/PlatformPrioritasIcon'
import { PublikasiIcon } from '@/components/Icons/PublikasiIcon'

import {
  DATA_INDUK_PTK_ROUTE,
  DATA_INDUK_ROUTE,
  DATA_INDUK_YAYASAN_ROUTE,
} from '@/configs/constants'

import { CarouselItem } from './CarouselItem'
import {
  SEARCH_GROUP_DATA_INDUK_PTK,
  SEARCH_GROUP_DATA_INDUK_SATPEN,
  SEARCH_GROUP_DATA_INDUK_YAYASAN,
} from '../../constants'

const getAvailableDataList = ({ withPtk }: { withPtk: boolean }) => {
  return [
    {
      title: 'Dataset',
      icon: <DatasetIcon height={24} width={24} />,
      items: [
        {
          link: '/dataset',
          text: 'Sekolah',
        },
        {
          link: '/dataset',
          text: 'Siswa',
        },
        {
          link: '/dataset',
          text: 'Pendidik dan Tenaga Kerja',
        },
      ],
    },
    {
      title: 'Data Induk',
      icon: <DataIndukIcon height={24} width={24} />,
      items: [
        {
          link: DATA_INDUK_ROUTE,
          text: SEARCH_GROUP_DATA_INDUK_SATPEN.name,
        },
        {
          link: DATA_INDUK_YAYASAN_ROUTE,
          text: SEARCH_GROUP_DATA_INDUK_YAYASAN.name,
        },
        withPtk
          ? {
              link: DATA_INDUK_PTK_ROUTE,
              text: SEARCH_GROUP_DATA_INDUK_PTK.name,
            }
          : null,
      ].filter(Boolean),
    },
    {
      title: 'Publikasi',
      icon: <PublikasiIcon height={24} width={24} />,
      items: [
        {
          link: '/publikasi?type=PUBLICATION_TYPE_BUKU_STATISTIK',
          text: 'Buku Statistik',
        },
        {
          link: '/publikasi?type=PUBLICATION_TYPE_ANALISIS',
          text: 'Analisis',
        },
        {
          link: '/publikasi?type=PUBLICATION_TYPE_STATISTIK_ONLINE',
          text: 'Statistik Online',
        },
      ],
    },
    {
      title: 'Platform Prioritas',
      icon: <PlatfromPrioritasIcon height={24} width={24} />,
      items: [
        {
          link: '/dashboard-prioritas#row-akun-belajar-id',
          text: 'Akun Belajar.id',
        },
        {
          link: '/dashboard-prioritas#row-merdeka-mengajar',
          text: 'Merdeka Mengajar',
        },
        {
          link: '/dashboard-prioritas#row-rapor-pendidikan',
          text: 'Rapor Pendidikan',
        },
        {
          link: '/dashboard-prioritas#row-lainnya',
          text: 'Lainnya',
        },
      ],
    },
  ]
}

const BASE_CAROUSEL_RESPONSIVE_SETTINGS = [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 640,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 360,
    settings: {
      slidesToShow: 1,
    },
  },
]

const BASE_CAROUSEL_SETTINGS = {
  arrows: false,
  autoplay: false,
  dots: false,
  infinite: true,
  variableWidth: true,
  adaptiveHeight: false,
  swipeToSlide: true,
  slideToShow: 1,
  slideToScroll: 1,
  initialSlide: 0,
}

const CAROUSEL_SETTINGS = {
  ...BASE_CAROUSEL_SETTINGS,
  responsive: BASE_CAROUSEL_RESPONSIVE_SETTINGS,
}

export const CustomCarousel = ({ sliderRef, withPtk = false }) => {
  return (
    <div className="mt-4" id="wrapper-carousel">
      <Slider
        ref={(slider) => {
          // @ts-ignore
          sliderRef.current = slider
        }}
        {...CAROUSEL_SETTINGS}
        className={styles.slider}
      >
        {getAvailableDataList({ withPtk }).map((item) => (
          <CarouselItem key={item.title} item={item} />
        ))}
      </Slider>
    </div>
  )
}
