import { Container } from '@/components/Container'

import { SchedulePublication } from './SchedulePublication'
import { ScheduleStatistik } from './ScheduleStatistik'

export const ScheduleContainer = () => {
  return (
    <>
      <div
        className="relative -mt-[11.5rem] bg-transparent md:-mt-[12.5rem]"
        id="schedule-publication"
      >
        <Container className="space-y-6">
          <SchedulePublication />
        </Container>
      </div>
      <section
        className="z-0 -mt-[16rem] bg-gray-10 py-8 pt-[18rem]"
        id="schedule-statistik"
      >
        <Container className="space-y-6">
          <ScheduleStatistik />
        </Container>
      </section>
    </>
  )
}
