import { getUniqueId } from '@/utils/misc'

import { trackViewPage } from './common'
import { sendTrackerGA } from '../gaTracker'
import { sendTrackerSatuEvent } from '../satuTracker'

export const trackViewBeranda = () => {
  trackViewPage({
    gaEventName: 'beranda',
    pageTitle: 'Beranda',
  })
}

export const trackSearchBeranda = ({ section, sub_section, keyword }) => {
  if (keyword !== '') {
    const trackerId = getUniqueId()
    const eventLabel = `${keyword} - ${section}${sub_section ? ' - ' + sub_section : ''}`
    sendTrackerGA({
      id: trackerId,
      eventName: 'beranda',
      eventAction: 'SEARCH_BUTTON_CLICKED',
      eventParams: {
        eventLabel: eventLabel,
      },
    })

    sendTrackerSatuEvent({
      id: trackerId,
      pageTitle: 'Beranda',
      activityType: 'SEARCH_BUTTON_CLICKED',
      eventParams: {
        keyword,
        section,
        sub_section,
      },
    })
  }
}

export const trackClickLinkInBeranda = ({ section, sub_section }) => {
  const trackerId = getUniqueId()
  const eventLabel = `${section} - ${sub_section}`
  sendTrackerGA({
    id: trackerId,
    eventName: 'beranda',
    eventAction: 'REDIRECT_LINK_CLICKED',
    eventParams: {
      eventLabel: eventLabel,
    },
  })

  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: 'Beranda',
    activityType: 'REDIRECT_LINK_CLICKED',
    eventParams: {
      section,
      sub_section,
    },
  })
}
